import {BrowserRouter, Route, Routes} from "react-router-dom";
import IndexPage from "./components/pages/index/IndexPage";
import NavBar from "./components/partials/navbar/NavBar";
import LoginPage from "./components/pages/login/LoginPage";
import EditPage from "./components/pages/edit/EditPage";

function App() {
    return (
        <BrowserRouter>
            <div className="app">

                {/*App body  todo add route to backend const routeList!!!*/}
                <div className="app__body">
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                <IndexPage/>
                            }
                        />
                        <Route
                            exact
                            path="/login"
                            element={
                                <LoginPage/>
                            }
                        />
                        <Route
                            exact
                            path="/editer4"
                            element={
                                <EditPage/>
                            }
                        />


                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
