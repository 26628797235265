import {useUserContext} from "../../../context/userContext/context";
import {
    capitalizeFirstLetter,
    copyStringToClipboard,
    findUniqueDuplicates,
    shuffle,
    timeSince
} from '../../../utils/utils'
import {Link, NavLink} from "react-router-dom";
import {useEffect, useState} from "react";

const EditPage = () => {
    const {user, rates, getPanelData, updateRates} = useUserContext()
    const [localRates, setLocalRates] = useState(rates)

    useEffect(() => {
        getPanelData()
    }, []);

    useEffect(() => {
        setLocalRates(rates)
    }, [rates]);

    const updateFunc = (e) => {
        let currency = e.target.getAttribute('data-cur')
        let update = {currency}
        let name = e.target.getAttribute('data-name')
        let current = [...localRates]
        current.find(a=>a.currency===currency)[name] = e.target.value
        setLocalRates(current)
    }
    const saveFunc = ()=>{
        localRates.forEach(lr=>{
            updateRates(lr)
        })
    }
    return <div className={'container'}>

        <div className={'exchange'}>
            {localRates.map(r => <div key={r.currency} className={'rate'}>
                <div className={'cur'}>
                    <img alt={r.currency} src={`/${r.currency}.gif`} className={'flag'}/>
                    <strong>{r.currency}</strong>
                </div>
                <div className={'buy'}>
                    <input value={r.buy} data-cur={r.currency} data-name={'buy'} onChange={updateFunc}/>
                </div>
                <div className={'sell'}>
                    <input value={r.sell} data-cur={r.currency} data-name={'sell'} onChange={updateFunc}/>
                </div>
            </div>)}
            <button onClick={saveFunc} style={{width:'50vw', fontSize:"2vw"}}> Save </button>
        </div>

    </div>
};
export default EditPage;
