import "./IndexPage.css";
import {useUserContext} from "../../../context/userContext/context";

import {useEffect, useState} from "react";
function useInterval(callback, delay) {
    useEffect(() => {
        const intervalId = setInterval(callback, delay);
        return () => clearInterval(intervalId);
    }, [callback, delay]);
}
const IndexPage = () => {
    const {user, rates, getPanelData, updateRates} = useUserContext()

    // Call the useInterval hook with your Axios request function and the delay
    useInterval(getPanelData, 5000); // 30000 milliseconds = 30 seconds

    useEffect(() => {
        getPanelData()
    }, []);
    return <div className={'container'}>
        <div className={'exchange'}>
            <div style={{textAlign:"center", width:"100%",fontSize:'2.5vw'}}> Обменно Бюро Добрин Митев-14 ЕООД</div>
            <div className={'rate title'}>
                <div className={'buy'}>
                    <span>Валута</span>
                    <strong>Currency</strong>
                </div>
                <div className={'buy'}>
                    <span>Купува</span>
                    <strong>Buy</strong>
                </div>
                <div className={'sell'}>
                    <span>Продава</span>
                    <strong>Sell</strong>
                </div>
            </div>
            <div className={'rate title'}>
                <div className={'buy'}>
                    <span>Валута</span>
                    <strong>Currency</strong>
                </div>
                <div className={'buy'}>
                    <span>Купува</span>
                    <strong>Buy</strong>
                </div>
                <div className={'sell'}>
                    <span>Продава</span>
                    <strong>Sell</strong>
                </div>
            </div>
            {rates.map(r => <div key={r.currency} className={'rate'}>
                <div className={'cur'}>
                    <img alt={r.currency} src={`/${r.currency}.gif`} className={'flag'}/>
                    <strong>{r.currency}</strong>
                    <small style={{fontSize:'1vw'}}>({r.units})</small>
                </div>
                <div className={'buy'}>
                    {r.buy?.toFixed(4)}
                </div>
                <div className={'sell'}>
                    {r.sell?.toFixed(4)}
                </div>
            </div>)}
            <div className={'rate info'}>
                <strong>Управител: ТЕЛ: 0878 274 967</strong></div>
            <div className={'rate info'}><strong>Информационен телефон на НАП: 0700 187</strong></div>
        </div>
    </div>;
};
export default IndexPage;
